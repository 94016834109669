<template>
  <g fill="none" fill-rule="evenodd">
    <g>
      <g>
        <g>
          <path
            fill="#FFD05D"
            d="M0 16.948C0 7.588 7.588 0 16.948 0s16.947 7.588 16.947 16.948-7.588 16.947-16.947 16.947C7.588 33.895 0 26.308 0 16.948"
            transform="translate(-537 -1699) translate(367 1691) translate(170.073 8.108)"
          />
          <path
            fill="#FDC453"
            d="M25.814 2.483c1.688 2.635 2.667 5.767 2.667 9.129 0 9.36-7.588 16.947-16.948 16.947-3.25 0-6.287-.916-8.867-2.503 3.012 4.703 8.283 7.82 14.282 7.82 9.36 0 16.947-7.588 16.947-16.948 0-6.11-3.233-11.463-8.08-14.445"
            transform="translate(-537 -1699) translate(367 1691) translate(170.073 8.108)"
          />
          <path
            fill="#FFD77F"
            d="M2.303 18.736C2.303 10.046 9.348 3 18.038 3c5.08 0 9.596 2.408 12.473 6.143-2.717-4.717-7.81-7.893-13.644-7.893-8.69 0-15.735 7.045-15.735 15.735 0 3.61 1.217 6.937 3.262 9.592-1.33-2.309-2.09-4.987-2.09-7.842"
            transform="translate(-537 -1699) translate(367 1691) translate(170.073 8.108)"
          />
          <path
            fill="#303030"
            d="M11.953 12.836c0 1.163-.56 2.105-1.25 2.105-.689 0-1.248-.942-1.248-2.105 0-1.162.56-2.105 1.249-2.105.69 0 1.249.943 1.249 2.105M24.44 12.836c0 1.163-.56 2.105-1.249 2.105-.69 0-1.248-.942-1.248-2.105 0-1.162.559-2.105 1.248-2.105.69 0 1.249.943 1.249 2.105"
            transform="translate(-537 -1699) translate(367 1691) translate(170.073 8.108)"
          />
          <path
            fill="#414042"
            d="M26.958 9.283c-.182-.053-.36-.103-.533-.151-.38-.107-.74-.207-1.088-.328-.52-.175-.986-.374-1.428-.607-.464-.246-.886-.52-1.29-.837-.442-.343-.852-.744-1.229-1.126-.021-.022-.054-.028-.082-.016-.028.013-.045.04-.044.071.029.579.257 1.177.662 1.73.37.5.867.922 1.434 1.22.534.28 1.132.452 1.732.498l.07.004c.673.035 1.281-.072 1.807-.32.028-.014.044-.043.042-.073-.002-.03-.023-.056-.053-.065M6.937 9.283c.182-.053.36-.103.533-.151.38-.107.74-.207 1.088-.328.52-.175.986-.374 1.428-.607.464-.246.886-.52 1.29-.837.442-.343.852-.744 1.229-1.126.021-.022.054-.028.082-.016.028.013.045.04.044.071-.028.579-.257 1.177-.662 1.73-.37.5-.867.922-1.434 1.22-.534.28-1.131.452-1.732.498l-.07.004c-.673.035-1.281-.072-1.807-.32-.028-.014-.044-.043-.042-.073.002-.03.024-.056.053-.065"
            transform="translate(-537 -1699) translate(367 1691) translate(170.073 8.108)"
          />
          <path
            fill="#5E4939"
            d="M10.54 24.24c-.178.02-.28-.198-.278-.362.009-.753.21-1.522.597-2.286.369-.703.895-1.354 1.526-1.888 1.25-1.046 2.912-1.648 4.562-1.654 1.677-.032 3.354.572 4.592 1.644 1.286 1.1 2.07 2.663 2.094 4.18.003.172-.114.396-.307.361-.16-.028-.29-.244-.406-.342-.181-.15-.369-.293-.56-.428-.384-.27-.787-.513-1.201-.734-1.526-.814-3.257-1.254-4.991-1.103-1.104.096-2.176.428-3.16.932-.592.302-1.151.664-1.678 1.068-.2.153-.529.563-.775.61l-.016.003"
            transform="translate(-537 -1699) translate(367 1691) translate(170.073 8.108)"
          />
        </g>
      </g>
    </g>
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
